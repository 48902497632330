if (frappe.boot && frappe.boot.core_setup && frappe.boot.core_setup.show_language_switch){
    $(document).ready(()=>{
        this.language_button = $(`<div class = "switch-lan"><label class="switch">
        <input ${frappe.boot.lang=="bn"?"checked":""} class="switch-input" type="checkbox" />
        <span class="switch-label" data-on="ENG" data-off="বাং"></span>
        <span class="switch-handle"></span> </label></div>`).insertBefore($('.dropdown-navbar-user'))
        this.language_button.on("click", ".switch-input", ()=> {
            let value = this.language_button.find('input').is(":checked")?"bn": "en"
            console.log(this.language_button.find('input').is(":checked"))
            console.log(value)
            frappe.db.set_value("User", frappe.session.user, "language", value).then(r=>{
                if(!r.xhr)
                    return frappe.ui.toolbar.clear_cache()
            })
        });
    })
}